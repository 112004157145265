import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { LookupsService } from 'src/app/services/lookups.service';
import { SlidesService } from 'src/app/services/slides.service';
import { SwalService } from 'src/app/services/swal.service';


export interface DialogData {
  caseId: number;
  caseIdType: CaseType;
}

@Component({
  selector: 'app-cassette-slide-reference',
  templateUrl: './cassette-slide-reference.component.html'
})
export class CassetteSlideReferenceComponent implements OnInit {
  loading = false;
  formGroup: FormGroup;
  loadingCassettes = false;
  cassetteList: Lookup[] = [];
  isSubmitted = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<CassetteSlideReferenceComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private lookupsService: LookupsService,
    private swalService: SwalService,
    private service: SlidesService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getAllContainerCassette();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      casseteeId: [null, Validators.required]
    });
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  getAllContainerCassette() {
    this.loadingCassettes = true;
    const query = {
      CaseId: this.data.caseId,
      caseType: this.data.caseIdType
    };
    this.lookupsService.getAllContainerCassette(query).subscribe(
      data => {
        this.loadingCassettes = false;
        this.cassetteList = data as [];
      }, err => {
        this.loadingCassettes = false;
        console.error(err);
      });
  }

  saveRecord() {
    this.isSubmitted = true;
    if (this.formGroup.valid) {
      this.loading = true;
      const data = {
        caseId: this.data.caseId,
        casseteeId: this.formGroup.controls.casseteeId.value,
        caseIdType: this.data.caseIdType
      } as any;
      this.service.addSlideReference(data).subscribe(() => {
        this.loading = false;
        this.swalService.swalSuccess();
        this.dialogRef.close(true);
      }, err => {
        this.loading = false;
        this.handleErrors(err);


      });
    }
  }

  handleErrors(err) {
    const errors = JSON.parse(err.detail);
    let message = '';
    if (err.detail) {
      errors.ErrorDTO.forEach((error) => {
        if (error.ErrorCode === 'NotAllowed') {
          message = error.Error;
        }
      });
    } else {
      message = err.title;
    }
    this.swalService.swalFailed(message);
  }

}

import { Lookup } from 'src/app/models/lookups/Lookup';
import { PathologistStatus } from '../cases/enums/PathologistStatus';
import { Pathologiest } from '../cases/Pathologiest';
import { CommunicationMethodType } from '../global/enums/CommunicationMethodType';
import { AttachmentsValueModel } from '../lookups/AttachmentsValueModel';
import { ModelsEnum } from '../users/users/enums/ModelsEnum';
import { QrData } from '../cases/QrData';

export class ListData {
  caseNumber: string;
  patientName: string;
  casesCount: number;
  viewUploadedByDesktop: boolean
  pagedData: PagedData[];
}

export class PagedData {
  slideId: number;
  specimenNumber: string;
  stainType: Lookup;
  selectedSlide: string;
  wsiSlide: AttachmentsValueModel;
  slideStatus: PathologistStatus;
  source: string;
  assignedPersons: Pathologiest[];
  creatorType: ModelsEnum;
  creatorId: number;
  generatedSlideReference: number;
  slideReferrenceId: number;
  communicationMethodType: CommunicationMethodType;
  canEdit: boolean;
  isDesktopWSI: boolean;
  qrToken: string;
  grossLabel: string;
  tissueType: string;
  iconText: string;
  showIcon: boolean;
  antibody: string;
  slideSpecimenNumber: number;
  isSlideViewed: boolean
  firstViewedDate: Date;
  cassetteId: string;
  qrData: QrData;
}

import { SlidesService } from 'src/app/services/slides.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Lookup } from 'src/app/models/lookups/Lookup';
import { Details } from 'src/app/models/slides/Details';
import { CasesService } from 'src/app/services/cases.service';
import { SlideForm } from 'src/app/models/slides/SlideForm';
import { LookupsService } from 'src/app/services/lookups.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CaseType } from 'src/app/models/cases/enums/CaseType';
import { CommunicationMethodType } from 'src/app/models/global/enums/CommunicationMethodType';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SwalService } from 'src/app/services/swal.service';
import { TissuesService } from 'src/app/services/tissues.service';

export interface DialogData {
  recordId: number;
  caseId: number;
  count: number;
  viewUploadedByDesktop: boolean;
  communicationMethods: {
    communicationMethodType: CommunicationMethodType;
    networkPath: string;
    userName: string;
    password: string;
  }[];
  record: Details;
  userId: number;
  userType: string;
  SentBackRequestId: number;
  caseCode: string;
  patientName: string;
  slideNumber: number;
  caseIdType: CaseType;
  ReferenceNumber: string;
  communicationMethodType: CommunicationMethodType;
  forGrossing: boolean;
  tissueType: any;
}

@Component({
  selector: 'app-slide-form',
  templateUrl: './slide-form.component.html',
})
export class SlideFormComponent implements OnInit {
  dataLoadingStain = false;
  loading = false;
  dataLoading = false;
  progress = 0;

  recordId: number;
  details: Details;

  formGroup: FormGroup;
  isSubmitted = false;

  slidesList = [];
  stainList: Lookup[] = [];
  antiBodyList: Lookup[] = [];
  slides = [];
  communicationMethods = [];
  containerList = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<SlideFormComponent>,
    private formBuilder: FormBuilder,
    private caseService: CasesService,
    private service: SlidesService,
    private tissuesService: TissuesService,
    private lookupsService: LookupsService,
    private swalService: SwalService
  ) { }

  ngOnInit(): void {
    console.log(this.data);

    this.buildForm();
    this.checkRequiredField();
    this.getStains();
    this.getlistCaseContainers();
    this.getSelectedSlides(true);
    this.getLookUpAntibody();
    if (this.data && this.data.record && this.data.recordId) {
      this.recordId = this.data.recordId;
      this.bindDataToUpdate();
    }
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      stainType: [null, Validators.required],
      IsDesktopWSI: [false],
      antibody: [''],
      antibodyList: [null],
      slideSpecimenNumber: ['', Validators.maxLength(50)],
      slideName: [''],
      communicationMethod: [
        CommunicationMethodType.NETWORK,
        Validators.required,
      ],
      slide: [null]
    });
  }

  bindDataToUpdate(record = this.data.record) {
    console.log(record);

    this.slides = record.wsiSlide ? [record.wsiSlide] : [];
    this.formGroup.controls.IsDesktopWSI.setValue(record.isDesktopWSI);
    if (record.antiBodyLkp) {
      this.formGroup.controls.antibodyList.setValue(
        record.antiBodyLkp.antiBodyId
      );
    } else {
      this.formGroup.controls.antibody.setValue(record.antibody);
    }
    this.formGroup.controls.slideSpecimenNumber.setValue(
      record.slideSpecimenNumber
    );
  }

  getStains() {
    this.dataLoadingStain = true;
    this.lookupsService.getListStainTypes().subscribe(
      (data) => {
        this.stainList = data as [];
        if (this.recordId) {
          this.formGroup.controls.stainType.setValue(
            this.stainList.find(
              (item) => item.id === this.data.record.stainType.id
            )
          );
        }
        this.dataLoadingStain = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getLookUpAntibody() {
    this.tissuesService.getListAntiBodies(this.data.tissueType.id, true).subscribe(
      (data) => {
        this.antiBodyList = data as [];
      },
      (err) => {
        console.error(err);
      }
    );
    this.antiBodyList = [];
    // ?TissueTypeId=33
  }

  getlistCaseContainers() {
    this.dataLoadingStain = true;
    const query = {
      caseType: this.data.caseIdType,
    };
    this.caseService.listCaseContainers(this.data.caseId, query).subscribe(
      (data) => {
        this.containerList = data as [];
        this.dataLoadingStain = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getSelectedSlides(event = false) {
    this.dataLoading = true;
    const param: any = {
      CaseId: this.data.caseId,
      SlideName:
        this.data.record && this.data.record.selectedSlide
          ? this.data.record.selectedSlide
          : '',
      caseIdType: this.data.caseIdType,
      CommunicationMethod: this.formGroup.controls.communicationMethod.value,
    };
    if (param.CommunicationMethod !== CommunicationMethodType.LOCAL) {
      this.caseService.getSelectedSlides(param).subscribe(
        (data) => {
          this.slidesList = data as [];
          if (
            this.recordId &&
            this.slidesList.length &&
            this.slidesList.includes(this.data.record.selectedSlide)
          ) {
            this.formGroup.controls.slide.setValue(
              this.data.record.selectedSlide
            );
          } else {
            this.formGroup.controls.slide.setValue(null);
          }
          this.dataLoading = false;
        },
        (err) => {
          this.slidesList = [];
          this.dataLoading = false;
          console.error(err);
        }
      );
    }
    if (
      event &&
      this.data &&
      this.data.record &&
      this.data.record.selectedSlide &&
      !this.data.communicationMethodType
    ) {
      this.formGroup.controls.slideName.setValue(
        this.data.record.selectedSlide
      );
      this.changeSlideName();
    }
  }

  saveRecord() {
    this.isSubmitted = true;
    if (
      this.slides.length === 0 &&
      !this.formGroup.controls.IsDesktopWSI.value &&
      this.formGroup.controls.communicationMethod.value ===
      CommunicationMethodType.LOCAL
    ) {
      return;
    }
    if (this.formGroup.valid) {
      this.loading = true;
      let data: SlideForm;
      const formData = new FormData();
      data = {
        stainType: this.formGroup.controls.stainType.value.id,
        caseId: this.data.caseId,
        isWSI: false,
        IsDesktopWSI: this.formGroup.controls.IsDesktopWSI.value,
        slideSpecimenNumber: this.formGroup.controls.slideSpecimenNumber.value
      } as SlideForm;
      if (this.formGroup.controls.antibodyList.value) {
        data.antibody = this.formGroup.controls.antibodyList.value;
        data.isLookupAntiBody = true;
      } else {
        data.antibody = this.formGroup.controls.antibody.value;
      }

      if (this.data.userId) {
        data.UserId = this.data.userId;
        data.UserType = this.data.userType;
      }
      if (this.data.SentBackRequestId) {
        data.SentBackRequestId = this.data.SentBackRequestId;
      }
      if (this.recordId) {
        data.CaseSlideId = this.recordId;
      }
      if (this.formGroup.controls.IsDesktopWSI.value) {
        data.selectedSlide = this.formGroup.controls.slideName.value;
      } else {
        data.isWSI =
          this.formGroup.controls.communicationMethod.value ===
          CommunicationMethodType.LOCAL;
        if (
          this.formGroup.controls.communicationMethod.value ===
          CommunicationMethodType.LOCAL
        ) {
          if (this.slides && this.slides.length) {
            this.slides.forEach((file) => {
              if (!file.id) {
                formData.append('slideAttachment', file, file.name);
              }
            });
          }
        }

        if (
          this.formGroup.controls.communicationMethod.value ===
          CommunicationMethodType.NETWORK ||
          this.formGroup.controls.communicationMethod.value ===
          CommunicationMethodType.FTP ||
          this.formGroup.controls.communicationMethod.value ===
          CommunicationMethodType.CEPH
        ) {
          data.selectedSlide = this.formGroup.controls.slide.value;
        }
        data.CommunicationMethodType =
          this.formGroup.controls.communicationMethod.value;
      }

      data.caseIdType = this.data.caseIdType;
      data.ReferenceNumber = this.data.ReferenceNumber;
      formData.append('data', JSON.stringify(data));
      this.progress = 0;
      this.service.saveRecord(formData, this.recordId).subscribe(
        (res) => {
          if (res.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 * res.loaded) / res.total);
          } else if (res instanceof HttpResponse) {
            this.progress = 0;
            this.loading = false;
            this.swalService.swalSuccess(
              this.recordId ? 'Updated Successfully' : 'Saved Successfully'
            );
            this.dialogRef.close(true);
          }
        },
        (err) => {
          this.loading = false;
        }
      );
    }
  }

  checkRequiredField() {
    if (this.data && this.data.communicationMethods) {
      this.communicationMethods = this.data.communicationMethods;
      if (
        !this.isInArray(
          this.communicationMethods,
          CommunicationMethodType.NETWORK
        )
      ) {
        this.formGroup.controls.communicationMethod.setValue(
          this.communicationMethods[0].communicationMethodType
        );
      }
    }

    if (
      this.data &&
      this.data.communicationMethodType &&
      !this.isInArray(
        this.communicationMethods,
        this.data.communicationMethodType
      )
    ) {
      this.communicationMethods.push(this.data.communicationMethodType);
    }

    if (this.data && this.data.communicationMethodType) {
      this.formGroup.controls.communicationMethod.setValue(
        this.data.communicationMethodType
      );
    }
  }
  reset() {
    this.formGroup.controls.antibodyList.setValue(null)
  }

  changeSlideName(event: MatSlideToggleChange = null) {
    if (event && !event.checked) {
      this.formGroup.controls.slideName.setValue('');
    } else if (!event) {
      this.formGroup.controls.IsDesktopWSI.setValue(
        this.formGroup.controls.slideName.value.trim() ? true : false
      );
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.formGroup.controls[controlName].hasError(errorName);
  }

  isInArray(parentObj, type) {
    return parentObj.some((obj) => obj.communicationMethodType === type);
  }
}

<div mat-dialog-title>
  <h2>Add Slide Reference</h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content>
  <div class="mat-dialog-body">
    <div class="mat-dialog-inner mb-0">
      <form [formGroup]="formGroup">
        <h4 class="label-form">Select Casstte</h4>
        <div class="fields-group">

          <mat-form-field [class.loading-field]="loadingCassettes">
            <mat-label>Select Casstte</mat-label>
            <mat-select disableOptionCentering formControlName="casseteeId">
              <mat-option [value]="item.id" *ngFor="let item of cassetteList" [matTooltip]="item.name">
                <span>{{item.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('casseteeId', 'required')">{{ 'forms.Required' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <button class="w-100" mat-raised-button color="primary" [disabled]="loading || !formGroup.valid"
          [class.loading]='loading' (click)="saveRecord()">
          Submit
        </button>
      </form>
    </div>
  </div>
</mat-dialog-content>